<!-- <div *ngIf='!loading' style="background: #999;">

    <body>
      <header>
        <h1>INVOICE</h1>
        <address>
          <p><b>Newtv</b></p>
          <p>23 Needham St, Newton Highlands</p>
          <p>MA, 02461</p>
          <p>(617) 965-7200</p>
        </address>
        <span><img src={{locationData.logo}} height="45" width="131"></span>
      </header>
      <article>
        <div class="row">
          <div class="col-sm-3" style="float: left; font-size: 100%;  font-weight: bold;">
            <p>{{locationData.name}}
              <br>{{locationData.address}}<br>
              {{locationData.city}}, {{locationData.state}} {{locationData.zip}}<br>
              Phone: {{locationData.phone}}
            </p>
          </div>
          <div class="col-sm-9">
            <table class="balance sT" style="float: right; width: 50%;">
              <tbody>
                <tr>
                  <th>Invoice #</th>
                  <td> {{locationData.nxtInvoiceId}}</td>
                </tr>
                <tr>
                  <th>Date </th>
                  <td> {{date}}</td>
                </tr>
                <tr>
                    <th>Payment Terms </th>
                    <td> Net 30 days</td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
  
        <br>
  
        <table class="inventory">
          <thead>
            <tr>
              <th>Item</th>
              <th class="desc">Description </th>
              <th class="amt">Amount</th>
            </tr>
          </thead>
  
          <tbody>
            <tr>
              <td class="tdreserv">
                <p>
                    Isaac Annual Tech Support
                </p>
              </td>
              <td class="tdreserv">
                <p>{{subscriptionDate}}</p>
              </td>
              <td class="tdreserv">
                   <span style="float: right;"> {{amount | currency:'INR'}}</span> 
            </td>
            </tr>
          </tbody>
        </table>
  
        <br>
        <table class="balance">
          <tbody>
            <tr>
              <th>Total</th>
              <td> {{amount | currency:'INR'}} </td>
            </tr>
            <tr>
              <th>Amount Paid</th>
              <td> {{amount | currency:'INR'}}</td>
            </tr>
          </tbody>
        </table>
        <div>
  
        </div>
      </article>
      <aside>
        <h1>
          <p></p> <br><br><br><span>NOTES</span>
        </h1>
        <div>
          <p><strong> {{locationData.Notes}}</strong></p>
        </div>
      </aside>
    </body>
  </div>
  <app-loading *ngIf="loading"></app-loading> -->

  <div class="col-lg-12 txt">
    We are working on Location renewal payment page and will get back to you soon.<br>
    For Isaac questions please email noreen@myisaac.com.<br><br>

    Thank you for choosing Isaac.
</div>