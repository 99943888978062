<div class="content">

    <mat-card class="card mat-elevation-z8">
        <h1>
            <div class="fa fa-check-circle"></div>
        </h1>
        <h1>You have Sign up Successfully!</h1>
        <h3>
            It is a two step process, you have completed first step and to continue further please check your mail and follow the Instructions.Thank you!
        </h3>

    </mat-card>

</div>