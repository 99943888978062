import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { MemberService } from 'src/app/services/member.service';

@Component({
  selector: 'app-inactive-loc-member',
  templateUrl: './inactive-loc-member.component.html',
  styleUrls: ['./inactive-loc-member.component.scss']
})
export class InactiveLocMemberComponent implements OnInit {
  currentUser;
  userInactive = false;
  locInactive = false;
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private memberService: MemberService
  ) { }

  ngOnInit(): void {
  //   this.currentUser = this.authService.currentUserValue
  //   if(this.currentUser.location.status == 'Active'){
  //     if(this.currentUser.status == 'Active'){
  //       this.router.navigate(['/dashboard']);
  //     } else {
  //       this.userInactive = true
  //       this.memberService.getMemberById(this.currentUser._id).subscribe(res => {
  //         if (res){
  //           if(res.data.status == 'Active'){
  //           this.router.navigate(['/login']);
  //         }}
  //       })
  //     }
  //   } else{ 
  //     this.locInactive = true
  //   }
  // }
  this.currentUser = this.authService.currentUserValue
    if(this.currentUser.location.status == 'Active'){
      if(this.currentUser.status == 'Active'){
        this.router.navigate(['/dashboard']);
      } else {
        if(this.currentUser.user_level >= 3 || this.currentUser.user_level == 0.5){
        this.router.navigate(['/dashboard']);

        } else{
          this.userInactive = true
        this.memberService.getMemberById(this.authService.currentUserValue.loc_id, this.currentUser._id).subscribe(res => {
          if (res){
            if(res.data.status == 'Active'){
            this.router.navigate(['/login']);
          }}
        })
        }
        
      }
    } else{ 
      this.locInactive = true
    }
  }

}
