import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LocationService } from 'src/app/services/location.service';
import { MemberService } from 'src/app/services/member.service';
import { MembersData } from '../../../shared/constants/membersData'
import moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { UserLevelData } from 'src/app/shared/constants/userRoles';
import { States } from 'src/app/shared/states';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { MemberSignupPaymentComponent } from '../member-signup-payment/member-signup-payment.component';
import { ConfirmDeleteDialogComponent } from 'src/app/components/confirm-delete-dialog/confirm-delete-dialog.component';

class formdataU {
  dateExpires: string
}
@Component({
  selector: 'app-member-signup',
  templateUrl: './member-signup.component.html',
  styleUrls: ['./member-signup.component.scss']
})
export class MemberSignupComponent implements OnInit {

  userLevel = UserLevelData.userLevels
  membersForm: FormGroup
  membershipList = []
  membershipListT = []
  volunteerList = MembersData.volunteerList
  id: string;
  access;
  Classification: string;
  name;
  mailingList;
  stateList;
  locationsList = []
  organisationList = []
  organisationListType = []
  uploadedFile;
  fieldTextType: boolean;
  checkMember;
  isEmailValid ;
  checkMail
  loading;
  isMemberIdValid = false;
  filterValue = null
  filterorga;
  pattern = /^\d+$/
  msg;
  selected;
  group;
  personId;
  updatedDate = moment().add(1, 'years').format('YYYY-MM-DD')
  organisationfiltered;
  checking = null;
  stateListfiltered;
  maxmember = 0;
  loadingf = false
  maxMemberMsg;
  userData
  themeColor
  public mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  currentDate = moment(new Date()).format('YYYY-MM-DD')
  constructor(
    private memberService: MemberService,
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private locationService: LocationService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) { }



  ngOnInit(): void {
    this.stateList = States.statesList;
    this.loadingf = true;
    this.route
      .queryParams
      .subscribe(params => {
        this.id = params.loc_id
        this.name = params.name
        if (params.tc) {
          this.themeColor = `#${params.tc}`
        } else if (params.c) {
          this.themeColor = params.c
        } else {
          this.themeColor = '#ff0000'
        }
      })
    this.getLocationInfo()
    this.confirmDialog()
    // this.getOrganisations()
    this.membersForm = this.formBuilder.group({
      // user_name: ['', [Validators.required, Validators.minLength(4)]],
      user_level: [{ value: '1', disabled: true }],
      loc_id: [{ value: '', disabled: true }],
      // pwd: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9]+$/)]],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      organizations: '',
      address: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      zipcode: ['', [Validators.required, Validators.pattern(/^[0-9]{5}(?:-[0-9]{4})?$/)]],
      tel: ['', [Validators.required, Validators.pattern(/\(\d{3}\)\s\d{3}-\d{4}/)]],
      work_phone: ['', Validators.pattern(/\(\d{3}\)\s\d{3}-\d{4}/)],
      home_phone: ['', Validators.pattern(/\(\d{3}\)\s\d{3}-\d{4}/)],
      user_email: ['', [Validators.required, Validators.email]],
      user_photo: '',
      dateJoined: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
      dateExpires: [this.datePipe.transform(new Date(), 'yyyy-MM-dd')],
      personID: '',
      user_age_under_18: '',
      user_age_under_13: '',
      parent_Name: '',
      parent_Phone: ['', [Validators.pattern(/\(\d{3}\)\s\d{3}-\d{4}/)]],
      parent_Email: ['', [ Validators.email]],
      member_type: ['', Validators.required],
      membership_fee: [''],
      volunteerAreas: [],
      mailingList: [],
      paperwork: '',
      memberNotes: '',
      ageGroup:'',
      loc_name: ['']
    })
    this.membersForm.get('loc_id').patchValue(this.name)
    this.membersForm.get('user_level').patchValue('User')
   // this.checkMemberId()

  }

  // radioChangeType(event: MatRadioChange) {
  //   const ev = event.value
  //   if( ev == "Individual" ){
  //     this.group = false
  //     this.individual = true
  //     this.membershipListT = this.membershipList.filter(e =>e.member_classification == ev)
  //   } 
  //   else if(ev == "Group"){
  //     this.group = true
  //     this.individual = false
  //     // this.membershipListT = this.organisationList
  //     console.log(this.organisationList)
  //   }
   
  // }
  confirmDialog() {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: "500px",
      disableClose: true,
      // panelClass: 'tool-bar',
      data: {
        message: 'Do you have an existing account?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        },
        themeColor: this.themeColor
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if(confirmed){
        this.router.navigate(['/login'])
      }
    });
  }
  
  updateOnclickGen(ev) {
    // console.log(ev);
    this.selected = true
    this.maxMemberMsg = '';
    if(ev.member_classification == "Group"){
      this.group = true
      this.organisationListType = this.organisationList.filter(e => e.member_type == ev.membership_type)
      this.organisationfiltered = this.organisationListType
      this.maxmember = ev.max_member_in_grp
    }else {
      this.group = false
    }
  }

  reLoad(){
    this.stateListfiltered = this.stateList
    this.organisationfiltered = this.organisationListType
  }

  applyStateFilter(str) {
    this.stateListfiltered = this.stateList
    this.stateListfiltered = this.stateList.filter(item => {
      return Object.keys(item).some(key => {
        return String(item[key]).toLowerCase().includes(str.toLowerCase());
      });
    });
  }
  checkEmail(event: Event) {
    const email = (event.target as HTMLInputElement).value;
    if (email != '') {
      this.memberService.checkEmail(this.id, email).subscribe(res => {
        if (res.status === 'success')
          this.checkMail = `Email-ID is already Taken`
        this.isEmailValid = false;
      }, err => {
        if (err.error.status === 'failure')
          this.checkMail = ``
        this.isEmailValid = true;
      })
    }
  }
  applyFilterOrg(str) {
    this.organisationfiltered = this.organisationListType
    this.organisationfiltered = this.organisationListType.filter(item => {
      return Object.keys(item).some(key => {
        return String(item[key]).toLowerCase().includes(str.toLowerCase());
      });
    });
  }
  
  selectMT(ev: MatSelectChange ){
    // console.log(ev.value) 
    let member_type = ''
    const organisationdata = this.organisationfiltered.filter(s => s.org_id == ev.value) 
    member_type = organisationdata[0].member_type 
    const memtype = member_type?true:false
    const nom = organisationdata[0].number_of_members?true:false
    // console.log(organisationdata)
    if(nom && organisationdata[0].number_of_members >= 0){
      // console.log('member_type yes')
      if(organisationdata[0].users < organisationdata[0].number_of_members){
        this.maxMemberMsg = '';
      } else{
          this.maxMemberMsg = 'Sorry \"' + organisationdata[0].org_name + '\" Group is Already Full'
      }
    } else if(memtype){
      // console.log(JSON.stringify(this.membershipList))
      const membershipD = this.membershipList.filter(s => s.membership_type == member_type)
      // this.membersForm.get('member_type').patchValue(member_type)
      // console.log(JSON.stringify(membershipD[0].max_member_in_grp) , organisationdata[0].users)
      if(organisationdata[0].users < membershipD[0].max_member_in_grp){
        this.maxMemberMsg = '';
      } else{
          this.maxMemberMsg = 'Sorry \"' + organisationdata[0].org_name + '\" Group is Already Full'
      }
    } else {
      // console.log('else')
        this.maxMemberMsg = '';
    }
    
  }

  radioChange(event: MatRadioChange) {
    if (event.value == 'Yes') {
      this.membersForm.get('parent_Email').setValidators([Validators.email, Validators.required]);
      this.membersForm.controls.parent_Email.updateValueAndValidity()
      this.membersForm.controls.parent_Name.setValidators(Validators.required);
      this.membersForm.controls.parent_Name.updateValueAndValidity()
      this.membersForm.controls.ageGroup.setValue('18 and under')
    } else {
      this.membersForm.controls.parent_Email.setValidators(null)
      this.membersForm.controls.parent_Email.setValue('')
      this.membersForm.controls.parent_Email.updateValueAndValidity()
      this.membersForm.controls.parent_Name.setValidators(null)
      this.membersForm.controls.parent_Name.setValue('')
      this.membersForm.controls.parent_Name.updateValueAndValidity()
      this.membersForm.controls.ageGroup.setValue('')
    }
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  getLocationInfo() {
    this.locationService.getLocationDetails(this.name, this.id).subscribe(res => {
      this.locationsList = res.data[0];
      this.organisationList = res.data[0].orgs.map(e => ({...e , label: e.org_name, value: e.org_id }))
      // this.filterorga = this.organisationList.map(e => ({...e , label: e.org_name, value: e.org_id }))
      this.membershipList = res.data[0].fee.filter(e =>  e.membership_status == 'Active' && e.can_signup == 'Yes' );
      // console.log( this.membershipList.length)
      if (res.data[0].status === "Active") {
        this.access = true;
      } else {
        this.access = false;
      }
      this.loading = false;
      this.loadingf = false;
    }, err => {
      console.log(err)
      this.loading = false;
      this.loadingf = false;
    })
  }

  compareOrg(o1: any, o2: any) {
    return o1.label === o2;
  }

  // getOrganisations() {
  //   this.memberService.getOrganisations(this.id).subscribe(res => {
  //     this.filterorga = res.data.map(e => ({ label: e.org_name, value: e.org_id }))
  //   }, err => {
  //     console.log(err)
  //   })
  // }
  // checkMemberId() {
  //     this.memberService.getMemberFormId(this.id).subscribe(res => {
  //       if (res && res.statusCode == 200) {
  //         this.personId = res.data.newMemberId
  //         this.membersForm.get('personID').patchValue(this.personId )
  //       }
  //     }, err => {
  //       console.log(err)
  //     })
  // }

  applyFilter(event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    if (this.filterValue.length >= 4) {
      this.checking = true
      this.memberService.checkForUserName(this.id, this.filterValue).subscribe(res => {
        // console.log(res)
        if (res.statusCode == 200) {
          this.checking = false
          this.msg = '<html><body><font color="#009900"> <strong>' + this.filterValue + '</strong>' + ' is Available </font></body></html>'
        } else {
          this.checking = false
          this.msg = '<font color="#FF0000"> <strong>' + this.filterValue + '</strong>' + ' is not Available </font>'
        }
      }, err => {
        console.log(err.message)
        this.checking = false
        this.msg = '<font color="#FF0000"> <strong>' + this.filterValue + '</strong>' + ' is not Available </font>'
      })
    }

  }


  uploadImage(event) {
    this.uploadedFile = event.target.files[0];
    this.membersForm.controls.user_photo.setValue(this.uploadedFile.name)
  }

  onKey(value) {
    this.filterorga = this.search(value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.filterorga.filter(option => option.toLowerCase().startsWith(filter));
  }

  submit() {
    if(this.isEmailValid){
    this.loadingf = true
    if(this.membersForm.valid){
    let payloadD = { ...this.membersForm.value }
    this.membersForm.removeControl('membership_fee')
    this.membersForm.removeControl('URL')
    this.membersForm.removeControl('loc_name')
    let payload = { ...this.membersForm.value }
    const fee = this.membersForm.value.member_type.member_fee
      payload.user_level = '1';
      payload.full_name = `${payload.first_name} ${payload.last_name}`
      // payload.URL = this.router.url
      payload.memberNotes = 'Registered through Member Sign up Link'
      payload.loc_id = this.id
      payload.volunteerAreas = ''
      // payload.personID = this.personId
      payload.mailingList = ''
      payload.member_type = this.membersForm.value.member_type.membership_type
      payload.mailingGroup = ''
      // console.log(JSON.stringify(payload))
      payloadD.loc_id = this.id
      payloadD.membership_amount = this.membersForm.value.member_type.member_fee
      payloadD.member_type = this.membersForm.value.member_type.membership_type
      payloadD.user_level = '1'
      // if(fee>0){
      // payload.status = "Pending"
      // }
      payloadD.URL = this.router.url
      payload.user_level = JSON.parse(payload.user_level)
      payloadD.full_name = `${payloadD.first_name} ${payloadD.last_name}`
      // console.log(payload.organizations)
      if (payload.organizations !== '')
          payload.organizations = typeof payload.organizations === 'object' ? payload.organizations : this.organisationList.find(f => f.label === payload.organizations)
        if (payload.CORI_exp_date === null)
          payload.CORI_exp_date = ''
        // let formData = new FormData();
        // if (this.uploadedFile) {
        //   delete payload.user_photo
        //   formData.append('user_photo', this.uploadedFile);
        // }
        // for (var key in payload) {
        //   formData.append(key, payload[key]);
        // }
      this.memberService.memberData = payloadD
      if (fee > 0) {
        this.memberService.createMember(this.id, payload).subscribe(res => {
          if (res && res.statusCode == 200) {
            this.loadingf = true
            this.userData = res.data
            this.makePayment(res.data)

          }
        }, err => {
          this.toastr.error('Something Went Wrong!');
          console.log(err)
          this.loadingf = false
        })
      } else{ 
        const today = new Date(payload.dateJoined);
        const nextYear = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate()-1);
        payload.dateExpires = this.datePipe.transform(new Date(nextYear), 'yyyy-MM-dd')
        this.memberService.createMember(this.id,payload).subscribe(res => {
          if (res && res.statusCode == 200) {
            this.loadingf = false               
            this.router.navigateByUrl('/member-sucess')
            this.toastr.success(res.message);
          }
        }, err => {
          console.log(err)
          this.loadingf = false
        })
      }
    }}
  }


  makePayment(res){
    this.loadingf = false

      const dialogRef = this.dialog.open(MemberSignupPaymentComponent, {
        width: "100vw",
        maxWidth: "1450px",
        minWidth: "450px",
        height: "750px",
        data: {
          data :this.locationsList,
          userData: res
        },
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res == 'payment') {
          // this.paymentDone()
          let payload:formdataU = new formdataU
              payload.dateExpires = this.updatedDate
              this.memberService.updateMember(this.id,payload,this.userData._id).subscribe(res => {
                this.router.navigateByUrl('/member-sucess')
                this.toastr.success('Member Created');
              }, err => {
                console.log(err)
              }) 
        }else{
          this.toastr.success('Member will be Inactive');
        }
      })
    }


    // paymentDone() {
    //   this.loadingf = false
    //   let payloadD = { ...this.membersForm.value }
    //   this.membersForm.removeControl('membership_fee')
    //   this.membersForm.removeControl('URL')
    //   this.membersForm.removeControl('loc_name')
    //   let payload = { ...this.membersForm.value }
    //   const fee = this.membersForm.value.member_type.member_fee
    //     payload.user_level = '1';
    //     payload.full_name =  payload.first_name + ' ' + payload.last_name
    //     // payload.URL = this.router.url
    //     payload.loc_id = this.id
    //     payload.volunteerAreas = ''
    //     payload.personID = this.personId
    //     payload.mailingList = ''
    //     payload.member_type = this.membersForm.value.member_type.membership_type
    //     payload.mailingGroup = ''
    //     // console.log(JSON.stringify(payload))
    //     payloadD.loc_id = this.id
    //     payloadD.membership_amount = this.membersForm.value.member_type.member_fee
    //     payloadD.member_type = this.membersForm.value.member_type.membership_type
    //     payloadD.user_level = '1'
    //     payloadD.URL = this.router.url
    //     payloadD.full_name = `${payloadD.first_name} ${payloadD.last_name}`
    //     // console.log(payload.organizations)
    //     if (payload.organizations !== '')
    //         payload.organizations = typeof payload.organizations === 'object' ? payload.organizations : this.organisationList.find(f => f.label === payload.organizations)
    //       if (payload.CORI_exp_date === null)
    //         payload.CORI_exp_date = ''
    //       let formData = new FormData();
    //       if (this.uploadedFile) {
    //         delete payload.user_photo
    //         formData.append('user_photo', this.uploadedFile);
    //       }
    //       for (var key in payload) {
    //         formData.append(key, payload[key]);
    //       }
    //     this.memberService.memberData = payloadD
    //     this.memberService.createMember(formData).subscribe(res => {
    //         if (res && res.statusCode == 200) {
    //           // this.router.navigateByUrl('/login')
    //           // this.toastr.success(res.message);
    //           this.loadingf = true
    //         }
    //       }, err => {
    //         console.log(err)
    //         this.loadingf = true
    //       })
    //   }
}
