import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  private violationSub = new Subject<any>();
  memberData;
  sendUpdate(message: string) {
    this.violationSub.next(message);
  }

  getUpdate(): Observable<any> {
    return this.violationSub.asObservable();
  }

  constructor(private http: HttpClient) { }

  // New Routes

  // Member Routes
  getMembers(page, limit, loc_id, filter = null, search = null, sort, sortorder) {
    let params = new HttpParams();
    if (filter) {
      for (var key in filter) {
        params = params.append(key, filter[key]);
      }
    }
    let url = ''
    // if (sort) {
    //   url = environment.SERVER_BASEURL + 'members?page=' + page + '&limit=' + limit + '&loc_id=' + loc_id + '&sort=' + sort
    // }
    if (search) {
      url = environment.SERVER_BASEURL + `member-management/${loc_id}/members?page=${page}&limit=${limit}&sort=${sort}&sortOrder=${sortorder}&search=${search}`
    } else {
      url = environment.SERVER_BASEURL + `member-management/${loc_id}/members?page=${page}&limit=${limit}&sort=${sort}&sortOrder=${sortorder}`
    }
    return this.http.get<any>(url, { params: params });
  }

  createMember(loc_id, payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/members`, payload)
  }

  updateMember(loc_id, payload, id) {
    return this.http.put<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/members/${id}`, payload)
  }

  getMemberById(loc_id, id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/members/${id}`);
  }

  getAllUsers(loc_id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/members-list`);
  }

  deleteMember(loc_id, id) {
    return this.http.delete<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/members/${id}`);
  }

  getActiveMembers(loc_id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/members-list`);
  }

  getMemberDBStatus(loc_id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/members/count`);
  }

  addViolation(loc_id, user_id, payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/members/${user_id}/violation`, payload)
  }

  getReservationList(loc_id, user_id, page, limit, search) {
    let url = environment.SERVER_BASEURL + `member-management/${loc_id}/members/${user_id}/reservations?page=${page}&limit=${limit}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(url);
  }

  getProjectList(loc_id, user_id, page, limit, search) {
    let url = ''
    if (search) {
      url = environment.SERVER_BASEURL + `member-management/${loc_id}/members/${user_id}/projects?page=${page}&limit=${limit}&search=${search}`
    } else {
      url = environment.SERVER_BASEURL + `member-management/${loc_id}/members/${user_id}/projects?page=${page}&limit=${limit}`
    }
    return this.http.get<any>(url);
  }

  getClasses(loc_id, user_id, page, limit, search,sort, sortorder) {
    let url = environment.SERVER_BASEURL + `member-management/${loc_id}/members/${user_id}/classes?page=${page}&limit=${limit}&sortBy=${sort}&sortOrder=${sortorder}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(url);
  }

  getPaymentList(loc_id, user_id, page, limit, search) {
    let url = environment.SERVER_BASEURL + `member-management/${loc_id}/members/${user_id}/payments?page=${page}&limit=${limit}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(url);
  }

  getVlounteerHoursData(loc_id, user_id, page, limit, startdate, enddate, search) {
    let url = environment.SERVER_BASEURL + `member-management/${loc_id}/members/${user_id}/volunteer-hours?page=${page}&limit=${limit}`
    if (search) {
      url = url + `&search=${search}`
    }
    if (startdate && enddate) {
      url = url + `&startdate=${startdate}&enddate=${enddate}`
    }
    return this.http.get<any>(url);
  }

  emailMember(loc_id, user_id, payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/members/${user_id}/e-mails`, payload)
  }

  getEmailLogs(loc_id, user_id, page, limit, search, sort, sortorder,userId) {
    let url = environment.SERVER_BASEURL + `member-management/${loc_id}/members/${user_id}/email-logs?page=${page}&limit=${limit}&sort=${sort}&sortOrder=${sortorder}`
    if (search) {
      url = url + `&mode=${search}`
    }if(userId){
      url = url + `&user_id=${userId}`
    }
    return this.http.get<any>(url);
  }

  getDocuments(loc_id, user_id, page, limit, search) {
    let url = environment.SERVER_BASEURL + `member-management/${loc_id}/members/${user_id}/documents?page=${page}&limit=${limit}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(url);
  }

  uploadDocument(loc_id, user_id, payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/members/${user_id}/documents`, payload)
  }

  deleteDocument(loc_id, user_id, id) {
    return this.http.delete<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/members/${user_id}/documents/${id}`);
  }

  getMemberFlags(loc_id, user_id, page, limit) {
    return this.http.get<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/members/${user_id}/flags?page=${page}&limit=${limit}`);
  }


  //Member-ship Routes
  getMembershipTypes(loc_id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/membership-types?page=${1}`);
  }

  getAllMembershipTypes(loc_id, page, limit, search, sort, sortorder) {
    let url = environment.SERVER_BASEURL + `member-management/${loc_id}/membership-types?page=${page}&limit=${limit}`
    if (search) {
      url = url + `&search=${search}`
    }
    if (sort && sortorder) {
      url = url + `&sortBy=${sort}&sortOrder=${sortorder}`
    }
    return this.http.get<any>(url);
  }

  addMembershipType(loc_id, payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/membership-types`, payload)
  }

  updateMembershipType(loc_id, payload, id) {
    return this.http.put<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/membership-types/${id}`, payload)
  }

  deleteMembershipType(loc_id, id) {
    return this.http.delete<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/membership-types/${id}`);
  }






  // Mailing List Routes
  getMailingList(loc_id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/loc-mail-list`);
  }



  // Old Routes
  getMemberFormId(loc_id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `members/new-member-id?loc_id=${loc_id}`);
  }

  // getAllMemberStatus(loc_id, mode) {
  //   return this.http.get<any>(environment.SERVER_BASEURL + `members/status?loc_id=${loc_id}&mode=${mode}`);
  // }

  checkMemberId(loc_id, personID) {
    return this.http.get<any>(environment.SERVER_BASEURL + `members/validate-member-id?loc_id=${loc_id}&personID=${personID}`);
  }

  checkForUserName(loc_id, username) {
    return this.http.get<any>(environment.SERVER_BASEURL + `members/validate-user-name?loc_id=${loc_id}&user_name=${username}`);
  }

  getOrganisations(id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `members/organizations?loc_id=${id}`);
  }

  renewMember(id, payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + `member/expire/${id}`, payload);
  }

  getMemberbyFilter(page, limit, loc_id, data) {
    let params = new HttpParams();
    for (var key in data) {
      params = params.append(key, data[key]);
    }
    return this.http.get<any>(environment.SERVER_BASEURL + 'members?page=' + page + '&limit=' + limit + '&loc_id=' + loc_id, { params: params });
  }

  updateProfilePic(payload, id) {
    return this.http.put<any>(environment.SERVER_BASEURL + `member/${id}` + '?upload_profile_pic=' + true, payload)
  }

  getCheckInOutList(loc_id, user_id, page, limit) {
    return this.http.get<any>(environment.SERVER_BASEURL + 'members/check-in?page=' + page + '&limit=' + limit + '&user_id=' + user_id + '&loc_id=' + loc_id);
  }

  createCheckInOut(payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + 'members/check-in', payload)
  }

  getDonations(loc_id, user_id, page, limit) {
    return this.http.get<any>(environment.SERVER_BASEURL + 'members/donations?page=' + page + '&limit=' + limit + '&user_id=' + user_id + '&loc_id=' + loc_id);
  }

  addUserCheckin(payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + 'members/check-in', payload)
  }

  getCheckins(loc_id, page, limit) {
    return this.http.get<any>(environment.SERVER_BASEURL + 'members/check-in-dashboard?page=' + page + '&limit=' + limit + '&loc_id=' + loc_id);
  }

  updateCheckins(id, payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + `members/check-in/${id}`, payload)
  }

  getStaffMembers(loc_id, search = null, page, limit) {
    let params = new HttpParams();
    let url = ''
    if (search) {
      url = environment.SERVER_BASEURL + `members/list?loc_id=${loc_id}&page=${page}&limit=${limit}` + '&search=' + search
    } else {
      url = environment.SERVER_BASEURL + `members/list?loc_id=${loc_id}&page=${page}&limit=${limit}`
    }
    return this.http.get<any>(url, { params: params });
  }

  updateUserPermission(id, payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + `members/permission/${id}`, payload)
  }
  getMembersStatus(loc_id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `members/dbstatus?loc_id=${loc_id}`)
  }

  getAllStaffMembers(loc_id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `members/drop-down?loc_id=${loc_id}`)
  }

  classSignUp(payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + 'signup/class', payload)
  }

  checkEmail(loc_id, email) {
    return this.http.get<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/members/email?email=${email}`);
  }
}
