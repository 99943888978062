export class UserLevelData {

    public static userLevels = [
        { value: "5", label: "Super Admin" },
        { value: "4", label: "Location Admin" },
        { value: "3.5", label: "Location Staff" },
        { value: "3", label: "Instructor" },
        { value: "1.5", label: "Community Partner" },
        { value: "1", label: "User" },
        { value: "0.5", label: "Classes Only" },
    ]

}