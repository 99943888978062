import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient) { }

  getLocations() {
    return this.http.get<any>(environment.SERVER_BASEURL + 'locations/drop-down');
  }
  getLocationsDBStats() {
    return this.http.get<any>(environment.SERVER_BASEURL + 'locations/count');
  }
  getAllLocations(page, size, search, mode,sortBy,sortOrder) {
    let url = environment.SERVER_BASEURL + `locations?page=${page}&limit=${size}&mode=${mode}&sortBy=${sortBy}&sortOrder=${sortOrder}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(url);
  }

  getLocationById(id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `location/${id}`);
  }

  getInvoiceId() {
    return this.http.get<any>(environment.SERVER_BASEURL + `locations/invoiceid`);
  }

  createLocation(payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + 'location', payload);
  }

  createLocationWidget(payload,loc_id) {
    return this.http.post<any>(environment.SERVER_BASEURL + `location/widgets/${loc_id}`, payload);
  }

  updateLocation(payload, id) {
    return this.http.put<any>(environment.SERVER_BASEURL + `location/${id}`, payload);
  }

  getBusinessHours(id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `locations/bizhrs?loc_id=${id}`);
  }

  updateBusinessHours(payload, id) {
    return this.http.put<any>(environment.SERVER_BASEURL + `location/bizhrs/${id}`, payload);
  }
  createBusinessHours(payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `location/bizhrs`, payload);
  }

  getAllExternalEmailNotifications(id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `location/email-notifications-external/?loc_id=${id}`);
  }

  getHoliBizHrs(id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `location/holidayhrs/${id}`);
  }

  createHoliBizHrd(payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + 'location/holidayhrs', payload);
  }

  updateHoliBizHrd(payload, id) {
    return this.http.put<any>(environment.SERVER_BASEURL + `location/holidayhrs/${id}`, payload);
  }

  deleteHoliBizHrs(id) {
    return this.http.delete<any>(environment.SERVER_BASEURL + `location/holidayhrs/${id}`);
  }

  getWidgetSettings(id,userId) {
    return this.http.get<any>(environment.SERVER_BASEURL + `location/widgets/${id}?user_id=${userId}`);
  }

  updateWidgetSettings(payload, id,userId) {
    return this.http.put<any>(environment.SERVER_BASEURL + `dashboard/settings?id=${id}&user_id=${userId}`, payload);
  }

  createExternalEmailNotifications(payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + 'location/email-notification-external', payload);
  }

  updateExternalEmailNotifications(payload, id) {
    return this.http.put<any>(environment.SERVER_BASEURL + `location/email-notification-external/${id}`, payload);
  }

  deleteExternalEmailNotifications(id) {
    return this.http.delete<any>(environment.SERVER_BASEURL + `location/email-notification-external/${id}`);
  }

  getAllPlaybackSetups(id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `locations/telvue?loc_id=${id}`);
  }

  getPaymentGateways(id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `location/payment-gateway?loc_id=${id}`);
  }

  deletePaymentGateway(id) {
    return this.http.delete<any>(environment.SERVER_BASEURL + `location/payment-gateway/${id}`);
  }

  createPaymentGateway(payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + 'location/payment-gateway', payload);
  }

  updatePaymentGateway(payload, id) {
    return this.http.put<any>(environment.SERVER_BASEURL + `location/payment-gateway/${id}`, payload);
  }

  createPlaybackSetup(payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + 'locations/telvue', payload);
  }

  updatePlaybackSetup(payload, id) {
    return this.http.put<any>(environment.SERVER_BASEURL + `locations/telvue/${id}`, payload);
  }

  deletePlaybackSetup(id) {
    return this.http.delete<any>(environment.SERVER_BASEURL + `locations/telvue/${id}`);
  }

  getNotes(id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `location/notes?loc_id=${id}`);
  }

  deleteNotes(id) {
    return this.http.delete<any>(environment.SERVER_BASEURL + `location/notes/${id}`);
  }

  createNotes(payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + 'location/notes', payload);
  }

  updateNotes(payload, id) {
    return this.http.put<any>(environment.SERVER_BASEURL + `location/notes/${id}`, payload);
  }

  getAllBusinessHrsPermission(id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `locations/bizhrs/permissions?loc_id=${id}`);
  }

  addBusinessPermission(payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + 'locations/bizhrs/permissions', payload);
  }

  deleteBusinessPermission(id) {
    return this.http.delete<any>(environment.SERVER_BASEURL + `locations/bizhrs/permissions/${id}`);
  }

  getLocationDetails(loc_name, id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `signuploc?name=${loc_name}&loc_id=${id}`);
  }

  getAllLocationCSV() {
    return this.http.get<any>(environment.SERVER_BASEURL + `location/drop-down`)
  }

  updateLogoPic(id, payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + `location/image/${id}`, payload)
  }

  // checkLocStatus(id,null){
  //   return this.http.put<any>(environment.SERVER_BASEURL + `location/status/${id}`, null}
  // }
}
