import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClassSignupComponent } from './modules/authentication/class-signup/class-signup.component';
import { ErrorComponent } from './modules/authentication/error/error.component';
import { InactiveLocMemberComponent } from './modules/authentication/inactive-loc-member/inactive-loc-member.component';
import { LoginComponent } from './modules/authentication/login/login.component';
import { MemberSignupPaymentComponent } from './modules/authentication/member-signup-payment/member-signup-payment.component';
import { MemberSignupComponent } from './modules/authentication/member-signup/member-signup.component';
import { MemberSuccessPageComponent } from './modules/authentication/member-success-page/member-success-page.component';
import { ResetPasswordComponent } from './modules/authentication/reset-password/reset-password.component';
import { CreateDonationComponent } from './modules/fundraising/dashboard/new-donation/create-donation/create-donation.component';
import { PrintDonationComponent } from './modules/fundraising/dashboard/print-donation/print-donation.component';
import { AppLayoutComponent } from './modules/layout/app-layout/app-layout.component';
import { InvoiceGeneratorComponent } from './modules/payments/dashboard/invoice-generator/invoice-generator.component';
import { ViewPaymentComponent } from './modules/payments/dashboard/view-payment/view-payment.component';
import { PrintReservationComponent } from './modules/reservations/dashboard/print-reservation/print-reservation.component';
import { AuthGuard } from './shared/auth.guard';
import { Role } from './shared/roles';

const routes: Routes = [
  
  {
    path: '',
    canActivate:[AuthGuard],
    children: [{
      path: '',
      loadChildren: () => import('./modules/layout/layout.module').then(m => m.LayoutModule)
    }]
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path:'inactive-account',
    component:InactiveLocMemberComponent
  },
  {
    path:'reset-password',
    component:ResetPasswordComponent
  },
  {
    path:'member-signup',
    component:MemberSignupComponent
  },
  {
    path:'class-signup',
    component:ClassSignupComponent
  },
  {
    path:'member-sucess',
    component: MemberSuccessPageComponent
  },
  {
    path:'fundraising/new-donation',
    component:CreateDonationComponent
  },
  {
    path:'error',
    component:ErrorComponent
  },
  { path: 'membersignuppayment', component: MemberSignupPaymentComponent },
  {
    path:'logout',
    redirectTo:''
  },{
    path: 'fundraising/donation-print',
    component: PrintDonationComponent,
    // canActivate: [AuthGuard],
    // data: { access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER, Role.USER, ], module: 'Common' },
  },
  {
    path: 'reservations/reservation-print',
    component: PrintReservationComponent,
    // canActivate: [AuthGuard],
    // data: { access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER, Role.USER, ], module: 'Common' },
  },
  {
    path: 'payment/view-payment',
    component: ViewPaymentComponent,
    // canActivate: [AuthGuard],
    // data: { access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER, Role.USER, Role.CLASSES_ONLY ], module: 'Common' },
  },
  {
    path: 'payment/print-payment',
    component: ViewPaymentComponent,
    // canActivate: [AuthGuard],
    // data: { access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF, Role.INSTRUCTOR, Role.COMMUNITY_PARTNER, Role.USER, Role.CLASSES_ONLY ], module: 'Common' },
  },
  { 
    path: 'payments/invoice-generator', 
    component: InvoiceGeneratorComponent,
    canActivate: [AuthGuard],
    data: { access: [Role.SUPER_ADMIN, Role.LOCATION_ADMIN, Role.LOCATION_STAFF], module: 'Common' },
  },
  { path: '**', component: LoginComponent  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
