<form *ngIf="creditCardFormForStripe" name="creditCardFormForStripe" autocomplete="off"
    [formGroup]="creditCardFormForStripe"
    (ngSubmit)="cf.form.valid && validFormForStripe && payForCreditCardWithStripe()" #cf="ngForm"
    class="container-fluid">
    <mat-card class="form-card">
        <span class="card-heading"><i class="fa fa-credit-card" aria-hidden="true"></i>Credit Card</span>
        <br>
        <div class="row">
            <div class="col-sm-12">
                <br>
                <label class="input-label">Card Holder Name</label>
                <mat-form-field class="full-width" appearance="outline">
                    <input formControlName="name" matInput placeholder="Card Holder Name" value="Sushi">
                </mat-form-field>
            </div>
            <div class="col-sm-12" *ngIf="stripe">
                <label class="input-label">Credit Card Details<span>*</span></label>
                <ngx-stripe-card [stripe]="stripe" (change)="onChange($event)" [options]="cardOptions">
                </ngx-stripe-card>
            </div>

            <div class="col-sm-12">
                <br>
                <label class="input-label">Notes</label>
                <mat-form-field class="full-width" appearance="outline">
                    <textarea formControlName="Notes" rows="4" matInput placeholder="Notes"></textarea>
                    <mat-hint>e.g Payment Notes.</mat-hint>
                </mat-form-field>
            </div>
        </div>
    </mat-card>
    <br>
    <div class="row">
        <div class="col-sm-12">
            <button [disabled]="!validFormForStripe" [ngStyle]="buttonStyles" type="submit" class="primary-button"
                mat-raised-button>Pay
                Now</button>
        </div>
    </div>
</form>
<app-loading *ngIf="loading"></app-loading>