<div *ngIf="!loading">
    <form *ngIf="access" class="container-fluid" name="membersForm" autocomplete="off" [formGroup]="membersForm"
        (ngSubmit)="f.form.valid && submit()" #f="ngForm">
        <!-- <div class="row">
            <div class="col-6">
                <img src="https://staging.myisaac.com/app/css/logo-isaac.png?v=1611852652" class="img-fluid" />
            </div>
            <div class="col-6 d-flex align-items-sm-center justify-content-sm-end">
                <img [src]="locationsList.logo" style="max-height: 58px;" class="img-fluid" />
            </div>
        </div> -->
        <div class="row" style="margin: 0;">
            <div class="col-6">
                <img *ngIf='locationsList.logo' [src]="locationsList.logo" class="img-fluid logo" />

            </div>
            <div class="col-6 d-flex align-items-sm-end justify-content-sm-end">
                <!-- <img src="https://staging.myisaac.com/app/location/locationlogo/1-17-8-16-logo.png" class="img-fluid" /> -->
                <!-- <img *ngIf='logo' src="{{logo}}" class="img-fluid logo" /> -->
                <img src="../../../assets/isaac_powered_by logo.png" class="img-Isaac" />
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <mat-card class="card" style="width: 100%;" [style.borderColor]="themeColor">
                    <mat-toolbar class="tool-bar" [style.backgroundColor]="themeColor">
                        <!-- <h4> <span class="fa fa-user-plus"></span>Class Registration</h4> -->
                        <h4> <span class="fa fa-user-plus"></span>Class Only Membership</h4>
                    </mat-toolbar>

                    <div class="container-fluid">
                        <mat-card class="form-card">
                            <h2><span [style.color]="themeColor" class="card-heading"><i [style.color]="themeColor"
                                        class="fa fa-info-circle" aria-hidden="true"></i>Classes Details</span></h2>
                            <div class="row member-details">
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">Title<span>*</span></label>
                                    <mat-form-field class="full-width" (click)="reLoad()" appearance="outline">
                                        <mat-select (selectionChange)="getClasseSessions($event.value)"
                                            [disableOptionCentering]="true" formControlName="state">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel="Search"
                                                    noEntriesFoundLabel="No Matching Found"
                                                    (keyup)="applyFilterClass($event.target.value)">
                                                    <mat-icon (click)="reLoad()" ngxMatSelectSearchClear>close
                                                    </mat-icon>
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option matTooltip="{{Classes.label}}" matTooltipClass="customtooltip"
                                                *ngFor="let Classes of filteredClasses" [value]="Classes.value">
                                                <span [ngStyle]="{'color':Classes.fee==0 ? 'green' : 'red' }">{{Classes.label}} - Fee: {{Classes.fee | currency:'INR'}}</span>
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8">
                                    <label class="input-label">Sessions Details<span>*</span></label>
                                    <mat-form-field class="full-width" (click)="reLoad()" appearance="outline">
                                        <mat-select (selectionChange)="getClasseSchedule($event.value)"
                                            [disableOptionCentering]="true" formControlName="state"
                                            [disabled]="classId == undefined">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel="Search"
                                                    noEntriesFoundLabel="No Matching Found"
                                                    (keyup)="applyFilterSessions($event.target.value)">
                                                    <mat-icon (click)="reLoad()" ngxMatSelectSearchClear>close
                                                    </mat-icon>
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option
                                                matTooltip="Instructor: {{sessions.label}}, Start Date: {{sessions.startDate}}, End Date: {{sessions.endDate}}"
                                                matTooltipClass="customtooltip"
                                                [disabled]="getmemberavb(sessions.AvbCount)"
                                                *ngFor="let sessions of filteredSessions"
                                                [value]="sessions.classTimesID">
                                                <span *ngIf="getmemberavb(sessions.AvbCount)"
                                                    [ngClass]="getmemberavb(sessions.AvbCount)? 'full': '' ">FULL</span>
                                                <b>Instructor:</b> {{sessions.label}}, <b>Start Date:</b>
                                                {{sessions.startDate}},<b> End Date:</b> {{sessions.endDate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <br>
                                <div style="padding: 20px;">
                                    <div class="row" *ngIf="classId">
                                        <div class="col-sm-12 col-md-12 col-lg-4 ">
                                            <b>Title: </b> {{ classData.label}}
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-4 ">
                                            <b>Class Fee: </b><span [ngStyle]="{'color':classData.fee==0 ? 'green' : 'red' }"> {{ classData.fee==0?'Free':(classData.fee | currency:'INR')}} </span>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-12 ">
                                            <b>Class Description: </b>
                                            {{classData.classDescription}}
                                        </div>
                                    </div>
                                    <div *ngIf="classSessions">
                                        <div style="padding-top: 15px;">

                                            <mat-card class="class-time-details">
                                                <div class="row">
                                                    <div class="col">Instructor : <span
                                                            class="value">{{classSessions.instructor.full_name}}</span>
                                                    </div>
                                                    <div class="col">Start Date : <span
                                                            class="value">{{classSessions.startDate}}</span></div>
                                                    <div class="col">End Date : <span
                                                            class="value">{{classSessions.endDate}}</span></div>
                                                    <div class="col">Min Participants : <span
                                                            class="value">{{classSessions.minParticipants}}</span></div>
                                                    <div class="col">Max Participants : <span
                                                            class="value">{{classSessions.maxParticipants}}</span></div>
                                                    <div class="col">Total Schedules : <span
                                                            class="value">{{classSessions.total_sessions}}</span></div>
                                                </div>
                                            </mat-card>

                                        </div>
                                    </div>
                                </div>
                                <br>
                            </div>
                        </mat-card>
                    </div>

                    <div class="container-fluid">
                        <mat-card class="form-card">
                            <h2><span [style.color]="themeColor" class="card-heading"><i [style.color]="themeColor"
                                        class="fa fa-info-circle" aria-hidden="true"></i>User Details</span></h2>
                            <div class="row member-details">
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">First Name<span>*</span></label>
                                    <mat-form-field class="full-width" appearance="outline">
                                        <input matInput formControlName="first_name" required>
                                        <mat-error *ngIf="membersForm.controls['first_name'].errors?.required">First
                                            Name is
                                            required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">Last Name<span>*</span></label>
                                    <mat-form-field class="full-width" appearance="outline">
                                        <input matInput formControlName="last_name" required>
                                        <mat-error *ngIf="membersForm.controls['last_name'].errors?.required">Last
                                            Name is
                                            required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">User Level</label>
                                    <mat-form-field class="full-width" appearance="outline">
                                        <input class="input-text" matInput formControlName="user_level" required>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">Address<span>*</span></label>
                                    <mat-form-field class="full-width" appearance="outline" required>
                                        <input matInput formControlName="address">
                                        <mat-error *ngIf="membersForm.controls['address'].errors?.required">Address
                                            is
                                            required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">City<span>*</span></label>
                                    <mat-form-field class="full-width" appearance="outline" required>
                                        <input matInput formControlName="city">
                                        <mat-error *ngIf="membersForm.controls['city'].errors?.required">City is
                                            required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">State<span>*</span></label>
                                    <mat-form-field class="full-width" (click)="reLoad()" required appearance="outline">
                                        <mat-select [disableOptionCentering]="true" formControlName="state" >
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel="Search"
                                                    noEntriesFoundLabel="No Matching Found"
                                                    (keyup)="applyStateFilter($event.target.value)">
                                                    <mat-icon (click)="reLoad()" ngxMatSelectSearchClear>close
                                                    </mat-icon>
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let state of stateListfiltered" [value]="state.value">
                                                {{state.label}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="membersForm.controls['state'].errors?.required">State is
                                            required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">Zip Code<span>*</span></label>
                                    <mat-form-field class="full-width" appearance="outline" required>
                                        <input matInput formControlName="zipcode">
                                        <mat-error *ngIf="membersForm.controls['zipcode'].errors?.pattern">Zipcode
                                            is
                                            not valid</mat-error>
                                        <mat-error *ngIf="membersForm.controls['zipcode'].errors?.required">Zip Code
                                            is
                                            required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">Primary Phone<span>*</span></label>
                                    <mat-form-field class="full-width" appearance="outline" required>
                                        <input [textMask]="{mask: mask}" matInput formControlName="tel">
                                        <mat-error *ngIf="membersForm.controls['tel'].errors?.pattern">Primary Phone
                                            is
                                            not valid</mat-error>
                                        <mat-error *ngIf="membersForm.controls['tel'].errors?.required">Primary
                                            Phone is
                                            required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">Secondary Phone</label>
                                    <mat-form-field class="full-width" appearance="outline">
                                        <input [textMask]="{mask: mask}" matInput formControlName="work_phone">
                                        <mat-error *ngIf="membersForm.controls['work_phone'].errors?.pattern">
                                            Secondary Phone is
                                            not valid</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">Email<span>*</span></label>
                                    <mat-form-field class="full-width" appearance="outline" required>
                                        <input autocomplete="disabled" matInput (focusout)="checkEmail($event)"
                                            formControlName="user_email">
                                        <mat-error *ngIf="membersForm.controls['user_email'].errors?.email">Email is
                                            is not valid</mat-error>
                                        <mat-error *ngIf="membersForm.controls['user_email'].errors?.required">Email
                                            is
                                            required</mat-error>
                                        <mat-hint *ngIf="checkMail"
                                            [ngStyle]="isEmailValid?{'color':'green'} : {'color': 'red'}">
                                            {{checkMail}}
                                        </mat-hint>
                                    </mat-form-field>
                                </div>
                                <!-- <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                    <label class="input-label">Member Photo</label>
                                    <input type="file" class="custom-file-input" accept=".png, .jpg" id="customFile"
                                        (change)="uploadImage($event)">
                                    <label class="custom-file-label"
                                        for="customFile">{{membersForm.controls.user_photo.value}}</label>
                                    <div style="margin-top: 10px;">
                                        <span class="logo-label">Size: Width:500px/Height:323px (JPG and PNG Files
                                            Accepted)
                                        </span>
                                    </div>
                                </div> -->
                            </div>
                        </mat-card>
                        <br>
                        <div class="row">
                            <div class="col-sm-12 text-center">
                                <button [disabled]="!classSessions" [style.backgroundColor]="themeColor" type="submit"
                                    class="primary-button" mat-raised-button>
                                    <span class="fa fa-floppy-o"></span> <span *ngIf="!fee">Register</span><span *ngIf="fee">Class Registration Payment</span> 
                                </button>
                            </div>
                        </div>
                        <br>
                    </div>
                </mat-card>

            </div>
        </div>
    </form>

    <div class="notaccess" *ngIf="this.locationsList && !access">
        <p align="center"><img src="https://myisaac.com/app/images/access_denied.gif"> <br>
            <font size="+7" color="#D40000"><strong>{{locationsList.name}} Location is currently Inactive!!</strong>
            </font><br>
        </p>
        <h2 align="center">Please contact the location administrator if you think there has been an error!</h2>
    </div>
</div>
<app-loading *ngIf="loading"></app-loading>