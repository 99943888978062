<div style="background-image: linear-gradient(to left bottom, #add8e6, #79e0eb, #2ee6dd, #00eabc, #12eb8b);">
    <div class="container-fluid">
        <div class="row" style="    display: flex;
        justify-content: center;
        align-items: center;">
            <!-- <div class="row">
        <div class="offset-md-3 col-md-6 col-sm-12 col-xs-12 login"> -->
            <div class="logoSVGc col-md-6 col-sm-12 col-xs-12">
                <!-- <div style="text-align: left;"> -->
                <div class="container logoUI">
                    <!-- <div class="circle">
                        <div class="icon chair"></div>
                        <div class="icon table"></div>
                        <div class="icon laptop"></div>
                        <div class="icon tv"></div>
                        <div class="icon doc"></div>
                        <div class="icon monitor"></div>
                        <div class="icon online-work"></div>
                        <div class="icon workstation"></div>
                        <div class="icon icon3"></div>
                        <div class="icon remote-work"></div>
                        <div class="iconLI logoIMG"></div>
                        <div class="iconL logo"></div>
                    </div> -->
                    <img src="../../../../assets/SVG/logo img 2.svg" class="logoSVG">
                </div>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12" style="
            display: flex;
            justify-content: center;
            align-items: center;
        ">
        <!-- <img src="../../../../assets/logo (3).png"> -->
                <div class="login">
                    <div class=" login">
                        <mat-card class="card-style">
                            <mat-toolbar class="tool-bar">
                                <h4>Please Sign In</h4>
                            </mat-toolbar>
                            <div class="row">
                                <div class="col-sm-12">
                                    <form class="loginForm" name="loginForm" [formGroup]="loginForm"
                                        (ngSubmit)="f.form.valid && login()" #f="ngForm">
                                        <div class="form-group row">
                                            <div class="col-sm-12">
                                                <label class="input-label">Username or Email<span>*</span></label>
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <input matInput placeholder="Username or Email"
                                                        formControlName="username" required>
                                                    <mat-icon class="icon" matSuffix>account_circle</mat-icon>
                                                    <mat-error *ngIf="loginForm.controls['username'].invalid">Username
                                                        or Email
                                                        is
                                                        required</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-12">
                                                <label class="input-label">Password<span>*</span></label>
                                                <mat-form-field class="full-width" appearance="outline">
                                                    <input type="password" matInput placeholder="Password"
                                                        formControlName="password" required>
                                                    <mat-icon class="icon" matSuffix>vpn_key</mat-icon>
                                                    <mat-error *ngIf="loginForm.controls['username'].invalid">Password
                                                        is
                                                        required
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-sm-12">
                                                <button type="submit" [disabled]="loginForm.invalid"
                                                    class="btn btn-block loginbtn" mat-raised-button><i
                                                        class="fa fa-sign-in"></i> Login</button>
                                            </div>
                                            <div class="col-sm-12">
                                                <br />
                                                <mat-divider></mat-divider>
                                            </div>

                                        </div>
                                    </form>
                                    <div class="col-sm-12" style="padding-right: 0;">
                                        <a class="forgotBtn col-sm-6" [routerLink]="['forgot-password']"> Register as
                                            new
                                            user</a>
                                        <a class="forgotBtn col-sm-6" [routerLink]="['forgot-password']">Forgot
                                            Password?</a>
                                    </div>


                                </div>

                            </div>
                        </mat-card>

                    </div>
                </div>
            </div>
        </div>
        <app-loading *ngIf="isLoading"></app-loading>
    </div>
</div>