import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as _moment from 'moment';
import 'moment-timezone';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LocationService } from 'src/app/services/location.service';
import { MemberService } from 'src/app/services/member.service';
import { PaymentService } from 'src/app/services/payment.service';
const moment = _moment

import {
  StripeCardComponent,
  StripeFactoryService,
  StripeInstance
} from 'ngx-stripe';
@Component({
  selector: 'app-member-signup-payment',
  templateUrl: './member-signup-payment.component.html',
  styleUrls: ['./member-signup-payment.component.scss']
})
export class MemberSignupPaymentComponent implements OnInit {
  membersPaymentForm: FormGroup;
  memberShipFee
  checkForm: FormGroup;
  locationsList ;
  updatedDate = moment().add(1, 'years').format('YYYY-MM-DD')

  paymentDone = false
  paymentData
  cardTypes = ['American Express','Discover','Mastercard','Visa'];
  months = ['01 Jan.','02 Feb.','03 Mar.','04 Apr.','05 May.','06 Jun.','07 Jul.','08 Aug.','09 Sept.','10 Oct.','11 Nov.','12 Dec.'];
  years: Array<number> = [];
  formBuilder: any;
  stripe: StripeInstance;
  loading;
  paymentGateway
  userid
  url
  grandTotal
  memberDate;
  themeColor
  constructor(
    private router: Router,
    private memberService: MemberService,
    private paymentService: PaymentService,
    private toastr: ToastrService,
    private authService: AuthenticationService,
    private stripeFactoryService: StripeFactoryService,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<MemberSignupPaymentComponent>,
    private locationService: LocationService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route
    .queryParams
    .subscribe(params => {
      if (params.tc) {
        this.themeColor = `#${params.tc}`
      } else if (params.c) {
        this.themeColor = params.c
      } else {
        this.themeColor = '#ff0000'
      }
    })
    this.locationsList = this.data.data;
    this.memberDate = this.memberService.memberData
    this.userid = this.data.userData.id
    this.url = this.memberDate.URL
    // const name = this.url.split('=')[3]
    this.memberShipFee = this.data.classData?this.data.classData.amount:this.memberDate.membership_amount
    this.loading = true;
    this.membersPaymentForm = new FormGroup({
      user_name: new FormControl('', [Validators.required]),
      card_number: new FormControl('', [Validators.required]),
      card_type: new FormControl('', [Validators.required]),
      security_code: new FormControl('', [Validators.required]),
      month: new FormControl('', [Validators.required]),
      year: new FormControl('', [Validators.required]),
      note: new FormControl('' ),
    });
    this.getYears()
    this.getLocationInfo()
  }

  getLocationInfo() {
    // this.locationService.getLocationDetails(this.data,this.memberDate.loc_id).subscribe(res => {
      this.locationsList = this.data.data;
      this.locationService.getPaymentGateways(this.data.data.loc_id).subscribe(result => {
      let gw = result.data.filter(gw => gw.status === 'Active')[0]
      if (gw.gateway_name === 'Stripe') {
        this.stripe = this.stripeFactoryService.create(gw.gateway_password)
        this.paymentGateway = 'Stripe'
      } else if(gw.gateway_name === 'PayPal') {
        this.paymentGateway = 'PayPal'
      }else{
        this.paymentGateway = 'Square'
      }
      this.paymentGateway = gw.gateway_name
      this.loading = false;
    })
      this.loading = false;
  //   }, err => {
  //     console.log(err)
  //     this.loading = false;
  //   })
  }

  getYears(){
    const date = new Date()
    let year = date.getFullYear();
    for(let i=0;i<15;i++){
      var y = year + i;
      this.years.push(y)
    }
  }

  getPaymentDesc(){
    let paydesc = ''
    if(this.data.classData){
      paydesc = this.data.classData.title
    }else{
      paydesc = 'Member Signup Fees'
    }
    return paydesc
  }

  submit() {
    let payload = { ...this.membersPaymentForm.value }
    
    // console.log(payload)
  }
  payNow(payload) {
    this.loading = true
    payload.user_id = this.data.userData.id
    // payload.user_name = this.data.userData.user_name
    payload.full_name = this.data.userData.full_name
    // payload.unique_id = this.data.userData.unique_id
    payload.loc_id = this.data.userData.loc_id
    if (this.memberShipFee && !this.data.classData) {
      payload.next_renewal = this.updatedDate
    }
    if(this.data.classData){
      payload.payment_desc = this.data.classData.title
    }else{
      payload.payment_desc = 'Member Signup Fees'
    }
    console.log(this.data.data.loc_id,payload)

    this.paymentService.pay(this.data.data.loc_id,payload).subscribe(res => {
      if (res) {
        this.toastr.success(res.message);
        this.paymentDone = true
        this.paymentData = res.data
        this.dialogRef.close('payment');
        this.loading = false
      }
    }, err => {
      this.loading = false
      this.toastr.error(err.error.message);
      console.log(err)
    })
  }
  onPaymentResponse(paypalPaymentResult) {
    if (paypalPaymentResult.status) {
      this.paymentDone = true
      this.dialogRef.close('payment');
      this.paymentData = paypalPaymentResult.response
    }
  }
  cancel(){
    this.dialogRef.close('cancelled');
  }
  getListItems() {
    if (this.data.classData) {
      this.grandTotal = this.memberShipFee
      return {
        payment_desc: this.data.classData.title,
        purchaseItems: [{
          name: 'Class Payment',
          productID: {
            label: "Class Payment"
          },
          description: this.data.classData.title,
          price: this.memberShipFee,
          quantity: 1,
          total: this.memberShipFee,
          currency: "INR"
        }]
      }
    }else{
      this.grandTotal = this.memberShipFee
      return {
      payment_desc:'Member Signup Fees',
      purchaseItems: [{
          name: 'Member Signup',
          description: 'Member Signup Fees',
          price: this.memberShipFee,
          productID: {
            label: "Member Signup"
          },
          quantity: 1,
          total: this.memberShipFee,
          currency: "INR"
        }]
      }}
  }

  getLocId(){
    return this.data.userData.loc_id
  }
}
