import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(private http: HttpClient) { }

  // New Routes
  getAllOrgList(page, loc_id, size, search, filter,sort,sortorder) {
    let url = environment.SERVER_BASEURL + `member-management/${loc_id}/organizations?page=${page}&limit=${size}&sort=${sort}&sortOrder=${sortorder}`
    if (search) {
      url = url + `&search=${search}`
    }

    if (filter) {
      url = url + `&mode=${filter}`
    }
    return this.http.get<any>(url);
  }

  getOrgById(loc_id, id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/organizations/${id}`);
  }

  addOrg(loc_id, payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/organizations`, payload)
  }

  updateOrg(loc_id, id, payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/organizations/${id}`, payload)
  }
  
  deleteOrg(loc_id, id) {
    return this.http.delete<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/organizations/${id}`);
  }

  orgCount(loc_id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/organizations/count`);
  }

  getOrgReservations(page, loc_id, orgId, size, search) {
    let url = environment.SERVER_BASEURL + `member-management/${loc_id}/organizations/${orgId}/reservations?page=${page}&limit=${size}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(url);
  }

  getOrgProjects(page, loc_id, orgId, size, search) {
    let url = environment.SERVER_BASEURL + `member-management/${loc_id}/organizations/${orgId}/projects?page=${page}&limit=${size}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(url);
  }

  getOrgPayments(page, loc_id, orgId, size, search) {
    let url = environment.SERVER_BASEURL + `member-management/${loc_id}/organizations/${orgId}/payments?page=${page}&limit=${size}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(url);
  }

  getOrgEmailLogs(page, loc_id, orgId, size, search) {
    let url = environment.SERVER_BASEURL + `member-management/${loc_id}/organizations/${orgId}/email-logs?page=${page}&limit=${size}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(url);
  }

  getOrgUserDocs(page, loc_id, orgId, size, search) {
    let url = environment.SERVER_BASEURL + `member-management/${loc_id}/organizations/${orgId}/documents?page=${page}&limit=${size}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(url);
  }

  getOrgClasses(page, loc_id, orgId, size, search) {
    let url = environment.SERVER_BASEURL + `member-management/${loc_id}/organizations/${orgId}/classes?page=${page}&limit=${size}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(url);
  }

  getOrgCheckInouts(page, loc_id, orgId, size, search) {
    let url = environment.SERVER_BASEURL + `member-management/${loc_id}/organizations/${orgId}/check-ins?page=${page}&limit=${size}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(url);
  }

  getOrganisationsList(loc_id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/organizations-list`);
  }

  deleteDocument(loc_id, id,userId) {
    return this.http.delete<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/members/${userId}/documents/${id}`);
  }

  updateOrgProfilePic(loc_id, id,payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + `member-management/${loc_id}/organizations/${id}?upload_profile_pic=${true}`, payload)
  }


  // Old Routes
  getAllOrgStatus(loc_id, mode) {
    return this.http.get<any>(environment.SERVER_BASEURL + `members/organization/status?loc_id=${loc_id}&mode=${mode}`);
  }

  renewOrg(id, payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + `members/organization/expire/${id}`, payload);
  }

  uploadOrgDocs(payload) {
    console.log([...payload])
    return this.http.post<any>(environment.SERVER_BASEURL + 'members/organization/document', payload)
  }
}
