<div *ngIf="!loading && !loadingE && !loadingS"  style="background: #999;">
      <body  >
    <header>
      <h1>Equipment Reservation</h1>
      <address>
        <p><b>{{locationData.name | titlecase}}</b></p>
        <p>{{locationData.address}}, {{locationData.city}}<br>{{locationData.state}}, {{locationData.zip}}</p>
        <p>{{locationData.phone}}</p>
      </address>
      <span><img src={{locationData.logo}} height="45" width="131"></span>
    </header>
    <article>
      <h2> Order No #  {{reservationDetails.orderGroupID}}</h2>
      <br>
  
      <table class="inventory">
        <thead>
          <tr>
            <th>Member</th>
            <th>Project</th>
            <th>Start Date</th>
            <th>End Date</th>
          </tr>
        </thead>
  
        <tbody>
          <tr >
            <td> {{reservationDetails.user[0].full_name | titlecase}}</td>
            <td>{{reservationDetails.project[0]?reservationDetails.project[0].title:'-'}}</td>
            <td>{{reservationDetails.startOrderDate }}</td>
            <td>{{reservationDetails.endOrderDate  }}</td>
          </tr>
        </tbody>
      </table>
  
      <h2>List of Equipment: </h2>
      <br>
      <table class="inventory">
        <thead>
          <tr>
            <th width="20%">Tag#</th>
            <th width="25%">Custom Name</th>
            <th width="25%">Manufacturer</th>
            <th width="50%">Equipment Description</th>
            <th width="15%">Rent</th>
            <!-- <th width="20%">Equip. Replace Cost</th> -->
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let equip of equipmentList">
            <td>{{equip.TagNumber}}</td>
            <td>{{equip.custom_name?equip.custom_name:'-'}}</td>
            <td>{{equip.Manufacturer}}</td>
            <td>{{equip.Description}}</td>
            <td>
              <div align="right">{{equip.rent_cost | currency:'INR'}}</div>
            </td>
            <!-- <td>
              <div align="right"> {{equip.PurchaseCost?equip.PurchaseCost:'0' | currency:'INR'}}</div>
            </td> -->
          </tr>
        </tbody>
      </table>
      <h2>List of Supplies: </h2>
      <br>
      <table class="inventory">
        <thead>
          <tr >
            <th width="20%">Item</th>
            <th width="60%">Supplies Details</th>
            <th width="20%">Qty</th>
            <th width="15%">Supplies Cost</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let supplie of suppliesList">
            <td>{{supplie.supplies_name}}</td>
            <td>{{supplie.supplies_desc}}</td>
            <td>{{supplie.supplies_qty}}</td>
            <td>
              <div align="right">{{supplie.purchase_cost*supplie.supplies_qty | currency:'INR'}}</div>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="balance">
        <tbody>
          <tr>
            <th>Total Rent</th>
            <td> {{reservationDetails.total_rent_cost | currency:'INR'}}</td>
          </tr>
          <tr>
            <th>Total Supplies Cost</th>
            <td> {{totalSuppliesCost | currency:'INR'}}</td>
          </tr>
          <!-- <tr>
            <th>Grand Total </th>
            <td> {{totalSuppliesCost | currency:'INR'}}</td>
          </tr> -->
        </tbody>
      </table>
    </article>
    <aside>
      <p><strong>Note: {{reservationDetails.reservation_notes}}</strong></p>
      <h1 style="page-break-before: always">
        <p></p> <br><br><br><span>REAFFIRMATION OF RESPONSIBILITIES</span>
      </h1>
      <!-- <element ng-bind-html="{{locationData.reservation_Notes}}"></element> -->
      <div [innerHTML]="locationData.reservation_Notes" >
      </div>
      <!-- <small>Reservation prepared by : Test Admin on
        {{reservationDetails.addedDate | date: 'MMM d, y, h:mm a'}} </small> -->
    </aside>
  </body>
</div>