<div *ngIf='!loading' style="background: #999;">

  <body>
    <header>
      <h1>RECEIPT</h1>
      <address *ngIf="paymentData.payment_for != 'Isaac Annual Tech Support'">
        <p><b>{{locationData.name | titlecase}}</b></p>
        <p>{{locationData.address}}, {{locationData.city}}<br>{{locationData.state}}, {{locationData.zip}}</p>
        <p>{{locationData.phone}}</p>
      </address>
      <address *ngIf="paymentData.payment_for == 'Isaac Annual Tech Support'">
        <p><b>Another Age Productions</b></p>
        <p>23 Needham St.<br>Newton, MA 02461</p>
        <p>(617) 965-7200</p>
      </address>
      <span style="margin: 1em 1em" *ngIf="paymentData.payment_for == 'Isaac Annual Tech Support'"><img src='../../../../../assets/isaac_powered_by logo.png' height="30" width="100%"></span>
      <span *ngIf="paymentData.payment_for != 'Isaac Annual Tech Support'"><img src={{locationData.logo}} height="45" width="131"></span>
    </header>
    <article>
      <div class="row">
        <div *ngIf="paymentData.payment_for != 'Isaac Annual Tech Support'" class="col-sm-3"
          style="float: left; font-size: 100%;  font-weight: bold;">
          <p>{{paymentData.users.full_name}}
            <span *ngIf='paymentData.orgs.length >0'><br> {{paymentData.orgs[0].org_name}}</span>
            <br>{{paymentData.users.address}}<br>
            {{paymentData.users.city}}, {{paymentData.users.state}} {{paymentData.users.zipcode}}<br>
            Phone: {{paymentData.users.tel}}
          </p>
        </div>
        <div *ngIf="paymentData.payment_for == 'Isaac Annual Tech Support'" class="col-sm-3"
          style="float: left; font-size: 100%;  font-weight: bold;">
          <p>{{locationData.name | titlecase}}
            <br>{{locationData.address}}, {{locationData.city}}<br>
            {{locationData.state}}, {{locationData.zip}}<br>
            {{locationData.phone}}
          </p>
        </div>
        <div class="col-sm-9">
          <table class="balance sT" style="float: right; width: 50%;">
            <tbody>
              <tr *ngIf="paymentData.payment_refund != '' &&  paymentData.payment_refund != undefined">
                <th>Refund</th>
                <td> {{paymentData.payment_refund_date | date:'longDate' }}</td>
              </tr>
              <tr *ngIf="paymentData.payment_id  != undefined ">
                <th>Invoice #</th>
                <td> {{paymentData.payment_id}}</td>
              </tr>
              <tr>
                <th>Date </th>
                <td> {{paymentData.added_date | date:'longDate'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <br>

      <table class="inventory">
        <thead>
          <tr>
            <th>Item</th>
            <th class="desc">Description </th>
            <th>Payment Method </th>
            <th class="amt">Amount</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td class="tdreserv"> {{paymentData.payment_for}}
              <p *ngIf="reservation">
                <li *ngFor='let supplie of suppliesList'> &#187; {{supplie.supplies.supplies_name}}.
                  Qty:{{supplie.supplies_qty}}. Price: ${{supplie.supplies.purchase_cost}} </li>
              </p>
            </td>
            <td class="tdreserv ">
              <!-- {{paymentData.payment_desc?paymentData.payment_desc:'-'}} -->
              <p [innerHTML]="paymentData.payment_desc?paymentData.payment_desc:'-'"></p>

              <!-- <p *ngIf="paymentData.payment_method == 'Credit Card' ">Credit Card :{{paymentData.credit_card_number}}</p> -->
              <!-- <p *ngIf="  paymentData.payment_method != 'Credit Card' && paymentData.payment_method != 'CC'">{{paymentData.payment_method}}</p> -->
            </td>
            <td class="tdreserv">
              <p> {{getPaymentType(paymentData.payment_method)}}</p>
              <p
                *ngIf="(paymentData.payment_transaction_id !='') && (paymentData.payment_method == 'CC' || paymentData.payment_method == 'Credit Card')">
                Transaction Id:
                {{paymentData.payment_transaction_id?paymentData.payment_transaction_id:paymentData.unique_id}}</p>
            </td>
            <td class="tdreserv"> <span style="float: right;"> {{total | currency:'INR'}}</span> </td>
          </tr>
        </tbody>
      </table>

      <br>
      <table class="balance">
        <tbody>
          <tr>
            <th>Total</th>
            <td> {{total | currency:'INR'}} </td>
          </tr>
          <tr>
            <th>Amount Paid</th>
            <td> {{total | currency:'INR'}}</td>
          </tr>
        </tbody>
      </table>
      <div>

      </div>
    </article>
    <aside>
      <h1>
        <p></p> <br><br><br><span>NOTES</span>
      </h1>
      <!-- <element ng-bind-html="{{locationData.reservation_Notes"></element> -->
      <div>
        <p><strong> {{paymentData.Notes}}</strong></p>
        <p *ngIf="paymentData.payment_refund != '' &&  paymentData.payment_refund != undefined"> Refund on
          {{paymentData.payment_refund_date | date:'longDate' }}</p>
      </div>
    </aside>
  </body>
</div>